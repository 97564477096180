import React from "react"

const VideoAuditorio = ({ videoSrcURL, videoTitle, ...props }) => {
  return (
    <iframe
      style={{ height: "100%", width: "100%" }}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}

export default VideoAuditorio
