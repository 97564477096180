import React, { useRef, useState, useContext } from 'react';
import '../css/chat.css';

import firebase from "gatsby-plugin-firebase"
import 'firebase/firestore';

//import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { AuthContext } from "../context/auth"

const firestore = firebase.firestore();

function ChatAuditorios(props) {

 const { user } = useContext(AuthContext)

  return (
    <div className="ChatAuditorios">
   {/*   <header className="headerChat">
         <button>X</button>
      </header> */}

      <section className="sectionChat" style={{height: '97%'}}>
        {user ? <ChatRoom coleccion={props.auditorio}/> : ""}
      </section>

    </div>
  );
}

function ChatRoom({coleccion}) {
 const { user } = useContext(AuthContext)

  const dummy = useRef();
  const messagesRef = firestore.collection(`auditorios/${coleccion}/chats`);
 // const query = messagesRef.orderBy('createdAt').limit(25);
  const query = messagesRef.orderBy('createdAt');


  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');

  const sendMessage = async (e) => {
    e.preventDefault();

    //const { uid, photoURL } = auth.currentUser;
    const { uid, photoURL, displayName } = user;

    // puedo agregar cosas del localstorge si necesito aca
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main className="mainChat">

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>
{/*console.log("esta escuchando chat ", messages)*/}
    </main>

    <form className="formChat" onSubmit={sendMessage}>

      <input className="inputChat" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="..." />

     {/*  <button className="buttonChat" type="submit" disabled={!formValue}>🕊️</button> */}

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL, displayName } = props.message;
  const { user } = useContext(AuthContext)

  //const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  const messageClass = uid === user.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      {/* <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} /> */}
      {/* <p>{displayName}</p> */}
      <p>
      {uid !== user.uid ?<div><span style={{color: 'blue'}}>{displayName}</span><br/></div> : ""}
           {text}</p>
           {console.log('mensaje:' , text)}

    </div>
  </>)
}



export default ChatAuditorios;

/* function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
      <p>Do not violate the community guidelines or you will be banned for life!</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
} */
