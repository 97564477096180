import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  Modal,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
  Tooltip,
  //  Container,
} from "@material-ui/core"

import axios from "axios"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModalPreguntaSecreta = ({
  intl,
  nombre_auditorio,
  modal_preguntasecreta,
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  // envio a sheets

  const [data, setData] = useState({
    email: localStorage.getItem("email"),
    pregunta: "",
    nombre_auditorio: nombre_auditorio,
    nombre: localStorage.getItem("nombre"),
    apellido: localStorage.getItem("apellido"),
    fecha: Date.now().toString(),
  })

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setData({ ...data })
       axios
            .post(
                modal_preguntasecreta.link_planilla,
                data
            )
            .then(response => {
                setData({
                    pregunta: ""
                })
                setOpen(false)
                alert(currentLocale === "en"
                ? modal_preguntasecreta.alert_pregunta_enviada.en
                : currentLocale === "pt"
                ? modal_preguntasecreta.alert_pregunta_enviada.pt
                : modal_preguntasecreta.alert_pregunta_enviada.es)

            }) 
  }

  // envio a sheets END
  const currentLocale = intl.locale

  const body = (
    <div className={classes.paper}>
      <CssBaseline />
      <Typography component="h6" variant="h6" style={{fontSize: `${modal_preguntasecreta.titulo.fuente_size}vw`}}>
        <br />
        {currentLocale === "en"
                ? modal_preguntasecreta.titulo.en
                : currentLocale === "pt"
                ? modal_preguntasecreta.titulo.pt
                : modal_preguntasecreta.titulo.es}
      </Typography>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          name="pregunta"
          label={currentLocale === "en"
          ? modal_preguntasecreta.label_pregunta.en
          : currentLocale === "pt"
          ? modal_preguntasecreta.label_pregunta.pt
          : modal_preguntasecreta.label_pregunta.es}
          type="text"
          id="pregunta"
          value={data.pregunta}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            background: `${modal_preguntasecreta.boton_enviar_pregunta.color_fondo}`,
            color: `${modal_preguntasecreta.boton_enviar_pregunta.color_letra}`,
            marginTop: 20 
          }}
        >{currentLocale === "en"
        ? modal_preguntasecreta.boton_enviar_pregunta.en
        : currentLocale === "pt"
        ? modal_preguntasecreta.boton_enviar_pregunta.pt
        : modal_preguntasecreta.boton_enviar_pregunta.es}
        </Button>
      </form>
    </div>
  )

  return (
    <div>
      <Tooltip title={currentLocale === "en"
        ? modal_preguntasecreta.title.en
        : currentLocale === "pt"
        ? modal_preguntasecreta.title.pt
        : modal_preguntasecreta.title.es} arrow placement="top">
        <Button
          fullWidth
          variant={modal_preguntasecreta.variante}
          style={{
            background: `${modal_preguntasecreta.color_fondo}`,
            color: `${modal_preguntasecreta.color_letra}`,
          }}
          size={modal_preguntasecreta.size}
          onClick={handleOpen}
        >{currentLocale === "en"
        ? modal_preguntasecreta.texto.en
        : currentLocale === "pt"
        ? modal_preguntasecreta.texto.pt
        : modal_preguntasecreta.texto.es}
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalPreguntaSecreta)
