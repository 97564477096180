import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { AuthContext } from "../context/auth"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import VideoAuditorio from "./VideoAuditorio"
import { Button, Hidden, ListItem, ListItemText, Grid } from "@material-ui/core"
import ChatAuditorios from "../components/ChatAuditorios"
import esBandera from "../images/idiomas/100espana.svg"
import enBandera from "../images/idiomas/101estados-unidos.svg"
import ptBandera from "../images/idiomas/102brasil.svg"
import ModalPreguntaSecreta from "./ModalPreguntaSecreta"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Botones from "./Botones"
import Carteles from "../components/Carteles"
import CartelesMovil from "../components/CartelesMovil"
import firebase from "gatsby-plugin-firebase"

const Auditorios = ({
  data: {
    allStrapiAuditorios: { nodes },
    configuracion,
  },
}) => {
  const { user } = useContext(AuthContext)

  const [chat, setChat] = useState(false) //revisar siempre q este la importacion
  const {
    imagen_fondo,
    boton_chat,
    nombre,
    video,
    chat_activado,
    espago,
    botones,
    carteles,
    video_en_activado,
    video_pt_activado,
    video_en,
    video_pt,
    video_top,
    video_left,
    video_width,
    video_height,
    top_banderas,
    left_banderas,
    modal_preguntasecreta,
    playlist,
    videos_externos_leer,
  } = nodes[0]

  const [videoElegido, setVideoElegido] = useState(video)
  const [videoPrincipal, setVideoPrincipal] = useState(video)
  const [video2, setVideo2] = useState(video_en)
  const [video3, setVideo3] = useState(video_pt)
  const [video2Mostrar, setVideo2Mostrar] = useState(video_en_activado)
  const [video3Mostrar, setVideo3Mostrar] = useState(video_pt_activado)

  const [espagoUsuario, setEspagoUsuario] = useState(false)
  useEffect(() => {
    setEspagoUsuario(localStorage.getItem("espago"))
  }, [])

  //membresias.map(mem => (
  //console.log("membresias", mem.nombre)

  // si no es pago pasar a true puedeIngresar, o poner default probar
  //map de membresias strapi, adentro foreach o map de localStorage.getItem("membresias")
  //agregar a cabecera ese setitem de membresias
  // ver si array de membresias se guarda bien en localstorage
  //const [puedeIngresar, setPuedeIngresar] = useState(false)
  //))

  /**
   * videos externos desde firebase START
   */

  useEffect(() => {
    if (videos_externos_leer) {
      //listener de firebase y para grabar los videos elegidos o reemplazar lo de strapi
      //me parece tengo que useState y meter en useEffect el listener con unsuscribe
      var unsuscribe = firebase
        .firestore()
        .collection("auditorios")
        .doc(nombre)
        .onSnapshot(function (doc) {
          // localStorage.setItem("uid", doc.id)
          if (doc.exists) {
            //console.log("data doc auditorio", doc.data().video_principal)
            setVideoElegido(doc.data().video_principal)
            setVideoPrincipal(doc.data().video_principal)
            if (doc.data().video_2_activo) {
              setVideo2(doc.data().video_2)
              if (video_en_activado) {
                setVideo2Mostrar(true)
              }
            } else {
              setVideo2Mostrar(false)
            }
            if (doc.data().video_3_activo) {
              setVideo3(doc.data().video_3)
              if (video_pt_activado) {
                setVideo3Mostrar(true)
              }
            } else {
              setVideo3Mostrar(false)
            }
          } else {
            // no existe el documento
          }
        })
      return function cleanup() {
        unsuscribe()
      }
    }
  }, [])
  /**
   * videos externos desde firebase END
   */
  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)

  return (
    <>
      {(user != null && !espago) ||
      (user != null &&
        espago &&
        espagoUsuario !== "undefined" &&
        espagoUsuario !== "false" &&
        espagoUsuario !== false) ? (
        <Layout>
          <SEO title={nombre} />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              {/* {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))} */}
              {/** PLAYLIST AGREGAR LO DE ON HOVER AL BOTON ITEMTEXTLIST**/}
              {playlist.mostrar ? (
                <div
                  style={{
                    position: "absolute",
                    left: playlist.left,
                    top: playlist.top,
                    width: playlist.width,
                    height: playlist.height, //revisar para que siga para abajo
                    backgroundColor: `${playlist.color_fondo}`,
                    color: `${playlist.color_letra}`,
                    overflowY: "auto",
                    //scrollbarColor: "dark",
                  }}
                >
                  {playlist.videos.map(video => (
                    <>
                      {video.mostrar ? (
                        <div className="fondoBoton" key={video.id}>
                          <ListItem
                            style={{
                              backgroundColor: `${playlist.color_fondo_boton}`,
                            }}
                            button
                            onClick={() => setVideoElegido(video.link)}
                          >
                            <ListItemText>{video.texto}</ListItemText>
                          </ListItem>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}

              {botones.map(boton => (
                <div
                  key={boton.id}
                  style={{
                    position: "absolute",
                    left: boton.left,
                    top: boton.top,
                  }}
                >
                  <Botones boton={boton} />
                </div>
              ))}

              <div
                style={{
                  top: video_top,
                  left: video_left,
                  width: video_width,
                  height: video_height,
                  position: "absolute",
                }}
              >
                <VideoAuditorio
                  videoSrcURL={videoElegido}
                  videoTitle={nombre}
                />
              </div>
              {chat_activado && !chat && (
                <div
                  style={{
                    position: "absolute",
                    left: boton_chat.left,
                    top: boton_chat.top,
                    width: boton_chat.width,
                    //   backgroundColor: "blue",
                    //   borderRadius: 25,
                    //    padding: 10,
                  }}
                >
                  <Button type="button" onClick={() => setChat(true)}>
                    {boton_chat.imagen !== null &&
                    boton_chat.imagen.localFile !== null ? (
                      <GatsbyImage
                        image={
                          boton_chat.imagen.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt="chat"
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              )}
              {chat_activado && chat && (
                <div
                  style={{
                    position: "absolute",
                    right: "0%",
                    top: "0%",
                    backgroundColor: "#FFFFFF99",
                    height: "90%",
                    width: "20%",
                  }}
                >
                  {/* MISMO TAMAÑO width QUE LE PASO AL TOTAL DEL CHAT en css */}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => setChat(false)}
                  >
                    CERRAR CHAT X
                  </Button>
                  <ChatAuditorios auditorio={nombre} />
                </div>
              )}

              {modal_preguntasecreta.mostrar ? (
                <div
                  style={{
                    position: "absolute",
                    left: modal_preguntasecreta.left,
                    top: modal_preguntasecreta.top,
                  }}
                >
                  <ModalPreguntaSecreta
                    nombre_auditorio={nombre}
                    modal_preguntasecreta={modal_preguntasecreta}
                  />
                </div>
              ) : (
                ""
              )}

              <div
                style={{
                  position: "absolute",
                  top: top_banderas,
                  left: left_banderas,
                }}
              >
                {video2Mostrar && video3Mostrar ? (
                  <div>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={esBandera}
                        onClick={() => setVideoElegido(videoPrincipal)}
                        alt="es"
                      />
                    </Button>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={enBandera}
                        onClick={() => setVideoElegido(video2)}
                        alt="en"
                      />
                    </Button>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={ptBandera}
                        onClick={() => setVideoElegido(video3)}
                        alt="pt"
                      />
                    </Button>
                  </div>
                ) : video2Mostrar && !video3Mostrar ? (
                  <div>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={esBandera}
                        onClick={() => setVideoElegido(videoPrincipal)}
                        alt="es"
                      />
                    </Button>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={enBandera}
                        onClick={() => setVideoElegido(video2)}
                        alt="en"
                      />
                    </Button>
                  </div>
                ) : !video2Mostrar && video3Mostrar ? (
                  <div>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={esBandera}
                        onClick={() => setVideoElegido(videoPrincipal)}
                        alt="es"
                      />
                    </Button>
                    <Button type="button">
                      <img
                        width={40}
                        style={{ margin: 20 }}
                        src={ptBandera}
                        onClick={() => setVideoElegido(video3)}
                        alt="pt"
                      />
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))}
            </BackgroundImage>
          </Hidden>

          {/*      INICIO DE VERSION PARA CELULAR      */}
          <Hidden smUp>
            {configuracion.logo_movil !== null &&
            configuracion.logo_movil.localFile !== null ? (
              <GatsbyImage
                image={
                  configuracion.logo_movil.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="cabecera"
              />
            ) : (
              ""
            )}
            <div>&nbsp;</div>
            <div style={{ position: "absolute", left: "20%", bottom: "0%" }}>
              {video2Mostrar && video3Mostrar ? (
                <div>
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={esBandera}
                    onClick={() => setVideoElegido(videoPrincipal)}
                    alt="es"
                  />
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={enBandera}
                    onClick={() => setVideoElegido(video2)}
                    alt="en"
                  />
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={ptBandera}
                    onClick={() => setVideoElegido(video3)}
                    alt="pt"
                  />
                </div>
              ) : video2Mostrar && !video3Mostrar ? (
                <div>
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={esBandera}
                    onClick={() => setVideoElegido(videoPrincipal)}
                    alt="es"
                  />
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={enBandera}
                    onClick={() => setVideoElegido(video2)}
                    alt="en"
                  />
                </div>
              ) : !video2Mostrar && video3Mostrar ? (
                <div>
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={esBandera}
                    onClick={() => setVideoElegido(videoPrincipal)}
                    alt="es"
                  />
                  <img
                    width={40}
                    style={{ margin: 20 }}
                    src={ptBandera}
                    onClick={() => setVideoElegido(video3)}
                    alt="pt"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {modal_preguntasecreta.mostrar ? (
              <Grid item xs={12} key="preguntasecreta">
                <ModalPreguntaSecreta
                  nombre_auditorio={nombre}
                  modal_preguntasecreta={modal_preguntasecreta}
                />
              </Grid>
            ) : (
              ""
            )}
            <div>
              {/* <VideoAuditorio videoSrcURL={videoElegido} videoTitle={nombre} /> */}
            </div>

            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <Grid item xs={12}>
              {/** PLAYLIST AGREGAR LO DE ON HOVER AL BOTON ITEMTEXTLIST**/}
              {playlist.mostrar ? (
                <div
                  style={{
                    backgroundColor: `${playlist.color_fondo}`,
                    color: `${playlist.color_letra}`,
                    overflowY: "auto",
                    //scrollbarColor: "dark",
                  }}
                >
                  {playlist.videos.map(video => (
                    <div className="fondoBoton" key={video.id}>
                      <ListItem
                        style={{
                          backgroundColor: `${playlist.color_fondo_boton}`,
                        }}
                        button
                        //  key={video.id}
                        onClick={() => setVideoElegido(video.link)}
                      >
                        {video.mostrar ? (
                          <ListItemText>{video.texto}</ListItemText>
                        ) : (
                          ""
                        )}
                      </ListItem>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </Grid>
            <div>&nbsp;</div>
            {carteles.map(cartel => (
              <Grid item xs={12} key={cartel.id}>
                <CartelesMovil cartel={cartel} />
              </Grid>
            ))}
          </Hidden>
        </Layout>
      ) : (
        <div style={{ position: "absolute", top: "40%", left: "15%" }}>
          <h1>No tiene permiso para acceder a este contenido</h1>
        </div>
      )}
    </>
  )
}

export default Auditorios

export const query = graphql`
  query ($id: String!) {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allStrapiAuditorios(filter: { id: { eq: $id } }) {
      nodes {
        boton_chat {
          left
          top
          width
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        videos_externos_leer
        nombre
        espago
        chat_activado
        id
        nombre_en
        nombre_pt
        strapiId
        title
        title_en
        title_pt
        video
        video_en
        video_en_activado
        video_height
        video_left
        video_pt
        video_pt_activado
        video_top
        video_width
        top_banderas
        left_banderas
        playlist {
          color_fondo
          color_letra
          color_fondo_boton
          height
          left
          mostrar
          nombre
          top
          width
          videos {
            id
            link
            texto
            mostrar
          }
        }
        modal_preguntasecreta {
          texto {
            es
            en
            pt
          }
          title {
            es
            en
            pt
          }
          left
          top
          size
          variante
          color_fondo
          color_letra
          mostrar
          link_planilla
          alert_pregunta_enviada {
            es
            en
            pt
          }
          titulo {
            es
            en
            pt
            fuente_size
          }
          label_pregunta {
            es
            en
            pt
          }
          boton_enviar_pregunta {
            es
            en
            pt
            color_letra
            color_fondo
          }
        }
        imagen_fondo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        botones {
          id
          left
          link
          mostrar
          size
          texto {
            es
            en
            pt
          }
          tipo_link
          top
          variante
          title {
            es
            en
            pt
          }
          color_fondo
          color_letra
        }
        carteles {
          mostrar_celular
          ampliar_cartel
          mostrar
          nombre
          proporcion
          left
          id
          tiempo_slideshow
          top
          width
          marco_size
          color_fondo_marco
          slideshow_imagenes {
            id
            imagen {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 1000
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            imagen_link
            imagen_link_tipo
          }
        }
      }
    }
  }
`
